@import 'variables';
@font-face {
  font-family: "Space Mono";
  src: url("./fonts/SpaceMono-Bold.ttf") format("truetype");
  font-style: bold;
  font-weight: bold;
}

@font-face {
  font-family: "Space Mono";
  src: url("./fonts/SpaceMono-Regular.ttf") format("truetype");
  font-style: normal;
  font-weight: normal;
}

@font-face {
  font-family: "Libre Franklin";
  src: url("./fonts/LibreFranklin-Regular.ttf") format("truetype");
  font-style: normal;
  font-weight: normal;
}

@font-face {
  font-family: "Libre Franklin";
  src: url("./fonts/LibreFranklin-Medium.ttf") format("truetype");
  font-style: medium;
  font-weight: medium;
}

@font-face {
  font-family: "Libre Franklin";
  src: url("./fonts/LibreFranklin-SemiBold.ttf") format("truetype");
  font-style: bold;
  font-weight: bold;
}

@font-face {
  font-family: "Libre Franklin";
  src: url("./fonts/LibreFranklin-Light.ttf") format("truetype");
  font-style: light;
  font-weight: 100;
}

body {
  font-family: "Libre Franklin", "Space Mono";
  margin: 0;
  padding: 0;
}
#root {
  position: relative;
  overflow: hidden;
}

#animatedBackground {
  position: absolute;
  z-index: -2;
  width: 100%;
  padding-top: 68px;
  height: 100%;
}

a {
 
  text-decoration: none;
  color: $color-text-black;
}
.filterButton{
  font-family: "Libre Franklin";
  transition: all 0.2s ease-in-out;
}
button:not(:disabled):active {
  transform: translateY(1px);
}

.navbar {
  z-index: 10;
  position: absolute;
  height: 68px;
  width: 100%;
  background: $color-white;
  box-shadow: 0px 2px 10px rgba(0, 0, 0, 0.15);
  color: $color-text-black;
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.navbar h3 {
  display: inline;
  font-family: "Libre Franklin";
  font-weight: 100;
  font-size: 1rem;
}

#navLeft {
  display: inline-block;
}
#leftOne {
  display: inline-block;
  padding-left: 40px;
}
#leftOne h1 {
  display: inline;
  font-family: "Space Mono";
  font-weight: bold;
  font-size: 1.25rem;
}

#leftOne h3 {
  margin-left: 12px;
  display: inline;
  font-family: "Libre Franklin";
  font-weight: 100;
  font-size: 1rem;
}

#navRight {
  display: inline-block;
  padding-right: 40px;
  padding-top: 5px;
  font-family: "Libre Franklin";
  font-weight: 100;
}

.card-container {
  margin: 0;
  padding-top: 68px;
  display: flex;
  justify-content: center;
  align-items: center;
  min-height: calc(100vh - (68px));
  min-height: calc(100dvh - (68px));
  position: relative;
}
.filterToggle-container {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100%;
}

.filterToggle {
  width: 100%;
  height: 100%;
  border: 0;
  background-color: $color-text-black;
  color: $color-white;
  cursor: pointer;
  font-size: 1rem;
  font-family: "Libre Franklin";
}

.wrapper{
  position: relative;
  height: auto;
}


.filterMenu {
  padding: 0;
  z-index: 5;
  width: 160px;
  height: 40px;
  background: $color-text-black;
  border-radius: 0 0 10px 10px;
  box-shadow: 0px 5px 5px 0px rgba(0, 0, 0, 0.2);
  position: absolute;
  top: 68px;
  left: 24px;
  display: flex;
  flex-direction: column;
  overflow: hidden;
  transition: height 0.3s ease;
}

.filterMenu.open {
  /* height: 348px; */
  height: 100%;
  width: 368px;
  left: 0;
  background-color: $color-white;
}

.filterMenu.open .filterToggle-container {
  display: block;
  width: auto;
  height: auto;
  padding-top: 40px;
  padding-bottom: 100px;
}

.filterMenu.open .filterToggle {
  background-color: $color-text-black;
  width: 100%;
  height: 40px;
  color: $color-white;
  border-radius: 10px;
}
.filterMenu.open .filterToggle-container, .filterCopy {
  margin: 20px 40px 20px 40px;
}

.filterMenu.open p {
  font-family: "Libre Franklin";
  font-size: 1rem;
  font-weight: 100;
}
.filterMenu.open h3 {
  font-family: "Libre Franklin";
  font-size: 24px;
  font-weight: normal;
}

.filterButton,
.filterButton-active {
  height: 52px;
  display: flex;
  font-size: 1rem;
  align-items: center;
  justify-content: center;
  padding: 0px 12px 0px 12px;
  background-color: $color-white;
 
  cursor: pointer;
  gap: 6px;
  font-family: "Libre Franklin";
}

.filterButton,.filterButton-active:nth-child(2n){
  border-bottom: .33px solid #a3a5a8;
  border-top: .33px solid #a3a5a8;
  border-left: 0px;
  border-right: 0px;
}

.filterButton-active {
  background-color: $color-text-black;
  color: $color-white;
  border: 1px solid $color-text-black;
}

.moodButtons {
  display: flex;
  flex-direction: column;
}

.react-swipeable-view-container > * {
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
}

.card {
  z-index: 2;
  width: 720px;
  min-height: 312px;
  background: $color-white;
  box-shadow: 0px 2px 20px rgba(0, 0, 0, 0.15);
  border-radius: 20px;
  padding: 40px;
  margin: 40px;
  transition: all 50ms ease;
}

.top-bar {
  display: flex;
  flex-direction: row;
  align-items: center;
  height: 24px;
}

.top-bar #divide {
  margin: 10px;
  height: 16px;
  width: 1px;
  background-color: $color-text-black;
}

.top-bar h3 {
  width: auto;
  height: 17px;
  left: 40px;
  top: 48px;

  font-family: "Libre Franklin";
  font-style: normal;
  font-weight: normal;
  font-size: 0.875rem;
  line-height: 17px;

  text-transform: uppercase;

  letter-spacing: 0.1em;

  color: $color-text-black;
}

.main-section {
  margin-top: 40px;
}

#questionText {
  font-family: "Libre Franklin";
  font-style: bold;
  font-weight: bold;
  font-size: 3rem;
  line-height: 140%;
}

#indicator {
  margin-top: 8px;
  height: 6px;
  width: 45px;
}

.workfocused-background {
  background-color: #0073e6;
}

.caring-background {
  background-color: #00ab82;
}

.daring-background {
  background-color: #8600b5;
}

.laidback-background {
  background-color: #d91472;
}

.energetic-background {
  background-color: #ff7f50;
}

.arrow-right {
  background: $bgColor-arrow-gray;
  border-radius: 14px;
  border: none;
  width: 68px;
  height: 68px;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  position: absolute;
  right: 0;
  margin-right: 24px;
}

.arrow-right img {
  width: 20px;
  height: auto;
}

.arrow-left {
  background: $bgColor-arrow-gray;
  border-radius: 14px;
  border: none;
  width: 68px;
  height: 68px;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  position: absolute;
  left: 0;
  z-index: 2;
  margin-left: 24px;
}

.arrow-left img {
  width: 20px;
  height: auto;
  transform: rotate(180deg);
}

.arrow-left:hover, .arrow-right:hover{
  background-color: #EEEEEE;

}

.welcomeTitle{
  display: flex;
  flex-direction: column;
  gap: 20px;
}

.welcomeTitle h3 {
  font-family: 'Libre Franklin';
  font-style: light;
  font-weight: 100;
  font-size: 1.5rem;
  line-height: 200%;
  margin: 0;
  color: $color-text-secondary;
}

.welcomeTitle h1{
  font-family: "Space Mono";
  font-style: normal;
  font-weight: 700;
  font-size: 3rem;
  text-transform: uppercase;
  margin: 0;
  line-height: 140%;
  color: $color-text-black;
}

.welcomeCopy {
  margin-top: 36px;
  margin-bottom: 72px;
  
}

.welcomeCopy p {
  font-family: "Libre Franklin";
  font-weight: 100;
  font-size: 1.5rem;
  color: $color-text-secondary;
  max-width: 706px;;
  line-height: 150%;
}

.startSwipe p {
  font-family: "Libre Franklin";
  font-style: normal;
  font-weight: 600;
  font-size: 1.5rem;
  margin: 0;
  color: $color-text-black;
}

.aboutContainer {
  padding-top: 68px;
  position: relative;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  min-height: 90vh;
}

.aboutCard h1{
  font-family: 'Libre Franklin';
  font-style: normal;
  font-weight: 600;
  font-size: 28px;
  margin: 0;
}

.aboutCard p{
  font-family: 'Libre Franklin';
  font-style: normal;
  font-weight: 300;
  font-size: 20px;
  line-height: 24px;
}

.aboutCard p:last-child{
  margin: 0;
}

.aboutCard a{
  text-decoration: underline;
}


.aboutAnimation {
  height: 100%;
  width: 100%;
  padding-bottom: 68px;
  position: absolute;
  z-index: 0;
  overflow: hidden;
}

#footer{
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  z-index: 2;
  background: $color-text-black;
  color: $color-white;
}

#footer-left{
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 20px;
  width: 464px;
  margin: 80px 120px 80px 120px;
}

#footer-left h1{
  font-family: 'Space Mono';
  font-style: normal;
  font-weight: 700;
  font-size: 80px;
  text-transform: uppercase;
  line-height: 0px;
}

#footer-left p{
  font-family: 'Libre Franklin';
  font-style: normal;
  font-weight: 300;
  font-size: 20px;
  line-height: 24px;
  margin: 0%;
  padding: 0;
}

#footer-left button{
  cursor: pointer;
  font-family: 'Libre Franklin';
  font-style: normal;
  background: none;
  border: none;
  color: #ffffff;
  font-size: 20px;
  font-weight: 500;
  border-bottom: 1px solid white;
  padding: 4px;
}

.footerContact{
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 40px;
}

.divider{
  margin: 0 120px 0 120px;
  width: auto;
  height: 1px;
  background-color: #707070;
  display: none;
}

#footer-right{
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 40px;
  width: 372px;
  margin: 80px 120px 80px 120px;
  
}

#footer-right h3{
  font-family: 'Libre Franklin';
  font-style: normal;
  font-weight: 700;
  font-size: 24px;
  padding-top: 20px;
  margin: 0;
}

#tools{
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 0px;
  gap: 20px;
}

#tools a{
  color: #ffffff;
  font-family: 'Libre Franklin';
  font-style: normal;
  font-weight: 300;
  font-size: 20px;


}


.aboutHeader {
  padding: 20px;
  display: flex;
  align-items: center;
  flex-direction: row;
  gap: 12px;
}

.aboutHeader img {
  max-width: 120px;
  height: auto;
}

.aboutTitle {
  text-align: none;
}

.aboutTitle h1 {
  display: inline;
  text-align: left;
  font-family: "Libre Franklin";
  font-style: normal;
  font-weight: normal;
  font-size: 1.25rem;
  line-height: 25px;
  color: $color-text-black;
}

.aboutTitle img {
  padding-left: 8px;
  display: inline;
  width: 16px;
  height: 16px;
}

.mobile {
  display: none;
}

#title {
  font-family: "Libre Franklin";
  font-style: bold;
  font-weight: bold;
  font-size: 3rem;
  line-height: 140%;
  text-transform: capitalize;
  margin: 0;
  padding-bottom: 16px;
}

#description {
  font-family: "Libre Franklin";
  font-size: 1.125rem;
  font-weight: 100;
  text-transform: unset;
  margin: 0;
  max-width: 640px;
}

.flex-group {
  padding-top: 52px;
  display: flex;
  align-items: center;
  gap: 10%;
}

.flex-group h3 {
  text-transform: uppercase;
  font-family: "Space Mono";
  font-size: 1rem;
  color: $color-text-black;
  margin: 0;
}

.flex-group p {
  font-family: "Libre Franklin";
  font-weight: 100;
  margin: 8px 0 0 0;
  font-size: 1rem;
}

.setup {
  width: 45%;
}
.time {
  width: 45%;
}

.instructions {
  padding-top: 52px;
  display: block;
}

.instructions h3 {
  text-transform: uppercase;
  font-family: "Space Mono";
  font-size: 1rem;
  color: $color-text-black;
  margin: 0;
}

.instructions ul {
  margin: 8px 0 0 0;
  max-width: 640px;
}

.instructions ul li {
  font-family: "Libre Franklin";
  font-weight: 100;
  font-size: 16px;
}

.instructions li:not(:last-child) {
  margin-bottom: 16px;
}

.readMore {
  margin-top: 52px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.readMore img {
  height: 24px;
  width: auto;
}

.readMore-btn {
  cursor: pointer;
  display: flex;
  gap: 8px;
  align-items: center;
  justify-content: center;
  border: 0;
  background: none;
  color: #74777c;
  font-size: 1rem;
  font-family: "Libre Franklin";
}

.readMore-btn p {
  margin: 0;
}

.rotate {
  transform: rotate(180deg);
}

.line {
  margin: 0 0 40px 0;
  height: 1px;
  width: 100%;
  background-color: #e8e8e9;
}

.tip h3 {
  padding-top: 52px;
  text-transform: uppercase;
  font-family: "Space Mono";
  font-size: 1rem;
  color: $color-text-black;
  margin: 0;
}

.tip p {
  max-width: 640px;
  font-family: "Libre Franklin";
  font-weight: 100;
  font-size: 1rem;
  margin: 8px 0 0 0;
}

.source h3 {
  padding-top: 52px;
  text-transform: uppercase;
  font-family: "Space Mono";
  font-size: 1rem;
  color: $color-text-black;
  margin: 0;
}

.source img {
  margin: 8px 0 0 0;
  height: 16px;
  width: auto;
}

.source a {
  cursor: pointer;
  display: flex;
  align-items: center;
  gap: 8px;
  width: fit-content;
}

.source p {
  font-family: "Libre Franklin";
  font-weight: 100;
  font-size: 1rem;
  margin: 8px 0 0 0;
}

.expandable-view {
  display: none;
}

.expanded {
  display: block;
}

.hidden {
  display: none;
}

.testbtn {
  width: 20px;
  height: 20px;
}

.contact{
  padding-top: 20px;
  max-width: 340px;
  display: flex;
  align-items: center;
  gap: 20px;
}

.contact img{
  height: 88px;

}

.contactInfo{
  display: flex;
  flex-direction: column;
  gap: 12px;
}

.contactInfo p{
  font-family: "Libre Franklin";
  font-size: 20px;
  font-weight: 600;
  color: $color-text-black;
  margin: 0;
}

.contactInfo a{
  text-decoration: none;
  font-size: 18px;
  font-weight: 300;
  border-bottom: 2px solid #EB3D35;
  color: $color-text-black;

}