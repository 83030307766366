@media (min-width: 1800px) {
  .card {
    width: 900px;
    min-height: 390px;
  }

  #questionText {
    font-size: 3.5rem;
  }

  .top-bar #divide {
    width: 2px;
  }

  .top-bar h3 {
    font-size: 1rem;
  }
  #indicator {
    width: 55px;
  }
}

@media (max-width: 1330px) {


  #footer {

    padding: 0 40px 0 40px;
  }
}

@media (max-width: 1025px) {
  .arrow-right {
    display: none;
  }

  .arrow-left {
    display: none;
  }

  #questionText {
    font-size: 2.25rem;
  }
  #footer{
    flex-direction: column;
  }

  .divider{
    display: block;
  }
}

@media (max-width: 925px) {
  .welcomeTitle h1 {
    font-size: 2.75rem;
  }

  .card{
    width: auto;
  }

  .aboutCard{
    width: 70%;
  }

  .welcomeTitle h3 {
    font-size: 1.125rem;
  }

  .welcomeCopy {
    margin-bottom: 72px;
  }

  .welcomeCopy p {
    font-size: 1.25rem;
    max-width: 600px;
  }

  .startSwipe p {
    font-size: 1.25rem;
  }


}

@media (max-width: 699px) {
  .filterMenu {
    left: 20px;
  }
  .card-container {
    margin: 0;
  }
  .card {
    width: auto;
    min-height: 300px;
    margin: 20px;
  }

  #leftOne {
    display: block;
    padding-left: 20px;
  }

  #navRight {
    padding-right: 20px;
  }

  #footer {
    padding: 0 20px 0 20px;
  }


  .aboutCard{
    margin: 120px 20px 120px 20px;
  }

  .aboutCard p {
    font-size: 1rem;
  }

  #footer-left, #footer-right{
    width: auto;
  }

  #footer-left button{
    font-size: 1rem;
  }

  #footer-left h1{
    font-size: 3rem;
  }

  #footer-left p{
    font-size: 1rem;
  }

  #footer-right h3{
    font-size: 1.25rem;
  }

  #footer-left{
    margin: 80px 20px 20px 20px;
  }
 #footer-right{
    margin: 20px 20px 80px 20px;
  }

  .divider{
    margin: 0 20px 0 20px;
  }

  #tools a{
    font-size: 1rem;
  }

  .contactInfo a{
    font-size: 16px;
  }
}

@media (max-width: 635px) {

  .welcomeTitle h3{
    font-size: 1.125rem;
  }

  .welcomeTitle h1{
    font-size: 2rem;
  }

  .welcomeCopy{
    margin-bottom: 88px;
  }


  .welcomeCopy p {
    font-size: 1.125rem;

    color: #3c3c3c;
  }

  .startSwipe p {
    font-size: 1.125rem;
  }
}

@media (max-width: 530px) {

  .card {
    min-height: 230px;
    width: auto;
  }
  .aboutHeader {
    flex-direction: row;
    padding: 20px 20px 0 20px;
  }
  
  .aboutCard h1{
    font-size: 1.25rem;
  }
  .aboutContainer p {
    font-size: 1rem;
  }

  .welcomeTitle h1{
    font-size: 1.5rem;
  }

  .welcomeCopy{
    margin-top: 24px;
    margin-bottom: 60px;
  }


  .aboutHeader img {
    max-width: 60px;
    height: 60px;
  }

  .aboutImage {
    padding: 20px 0 0 0;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .aboutTitle {
    display: block;
    text-align: left;
  }
  .aboutTitle img {
    padding-left: 8px;
    display: inline;
    width: 16px;
    height: 16px;
  }

  .aboutCardText {
    padding: 0 20px 20px 20px;
  }

  .web {
    display: none;
  }



}

@media (max-width: 420px) {
  .card-container {
    margin: 0;
  }
  .card {
    min-height: 230px;
    width: auto;
    padding: 24px;
    margin: 20px;
  }

  .aboutCard{
    margin: 60px 20px 60px 20px;
  }

  .top-bar h3 {
    font-size: 0.75rem;
  }
  #questionText {
    font-size: 1.625rem;
  }

  .welcome {
    flex-direction: column;
  }

  .welcomeTitle{
    gap: 16px;
  }

  .welcomeTitle h3{
    font-size: 1rem;
  }


  .welcomeCopy{
    margin: 0 0 68px 0;
  }

  .welcomeCopy p {
    font-size: 1rem;
  }

  .startSwipe p {
    font-size: 1rem;
  }

  .filterMenu.open{
    width: 90%;
  }

  .filterMenu.open h3{
    font-size: 1.25rem;
  }

  .filterMenu.open p{
    font-size: 0.875rem;
  }

  .filterButton{
    font-size: 0.875rem;
  }
}

@media (max-width: 380px) {

  .welcomeCopy{
    margin: 0 0 40px 0;
  }

  .startSwipe p{
    max-width: 250px;
  }
  .contact img{
    height: 60px;
  }

  .contactInfo{
    gap: 8px;
  }
}

@media (max-width: 368px){
  .welcomeTitle h1{
    font-size: 1.375rem;
  }

}

@media (max-height: 450px) and (orientation: landscape) {

  .card {
    min-height: 80px;
    padding: 30px;
    width: 400px;
  }

  .aboutCard{
    margin: 60px 20px 60px 20px;
  }

  .top-bar h3 {
    font-size: 0.75rem;
  }
  #questionText {
    font-size: 1.625rem;
  }

  .welcome {
    flex-direction: column;
  }

  .welcomeTitle{
    gap: 8px;
  }
  .welcomeTitle h1{
    font-size: 1.375rem;
  }

  .welcomeTitle h3{
    font-size: 1rem;
  }

  .welcomeCopy{
    margin: 0;
  }

  .welcomeCopy p {
    font-size: 1rem;
    margin-top: 8px;
  }

  .startSwipe p {
    font-size: 1rem;
  }

  .filterMenu{
    left: 0px;
    width: 100px;
    height: 32px;
    overflow-y: scroll;
  }

  .filterMenu.open{
    min-height: 100%;
  }

  .filterMenu.open h3{
    font-size: 1.25rem;
  }

  .filterMenu.open p{
    font-size: 0.875rem;
  }

  .filterButton{
    font-size: 0.875rem;
  }

  .filterToggle{
    font-size: 0.87rem;
  }
 

  #questionText {
    font-size: 1.5rem;
    margin-top: 0;
  }

  .keywordList button {
    height: 50px;
  }


  .welcome {
    justify-content: flex-start;
  }

  .welcomeCopy {
    margin-bottom: 20px;
  }

  .arrow-left,
  .arrow-right {
    display: none;
  }


  


}

